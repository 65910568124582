<template>
  <div>
    <div class="top">
      <div class="tops">
        <div class="top-down">
          <div class="down-title">
            客宝宝APP下载
          </div>
          <div class="down-content">
            使用客宝宝手机端软件，随时随地服务您的客户
          </div>
          <div class="down-img">
            <div class="img-item">
              <img src="../../../public/down_a_qr.png" alt="">
				<router-link to="/register">
					<div class="img-Android">
						Android下载
					</div>
				</router-link>
            </div>
            <div class="img-item">
              <img src="../../../public/down_i_qr.png" alt="">
				<router-link to="/register">
					<div class="img-IOS">
						ios下载
					</div>
				</router-link>
            </div>
          </div>
        </div>
        <div class="top-downIMG">
          <img src="../../../public/download_img.png" alt="">
        </div>
      </div>
    </div>
    <div class="shopping">
      <div class="shopping-title">
        应用市场下载入口
      </div>
      <div class="shopping-list">
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download3.png" alt="">
          </div>
          <div class="item-title">
            华为市场
          </div>
        </div>
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download1.png" alt="">
          </div>
          <div class="item-title">
            OPPO软件商店
          </div>
        </div>
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download2.png" alt="">
          </div>
          <div class="item-title">
            VIVO应用商店
          </div>
        </div>
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download5.png" alt="">
          </div>
          <div class="item-title">
            小米市场
          </div>
        </div>
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download4.png" alt="">
          </div>
          <div class="item-title">
            腾讯应用宝
          </div>
        </div>
        <div class="list-item">
          <div class="item-img">
            <img src="../../../public/download6.png" alt="">
          </div>
          <div class="item-title">
            APP store
          </div>
        </div>

      </div>
      <div class="notice">
        Android（安卓）手机，可以在手机各大应用市场搜索【客宝宝】；iPhone（苹果）手机可以在APP store搜索【客宝宝】
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",
  mounted() {
    console.log("1111", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobileDownload')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/download')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>

<style lang="scss" scoped>
a{ text-decoration:none} 
.top {
  height: 600px;
  padding: 50px;
  background: url("../../../public/download_bg.png");

  .tops {
    display: flex;
    justify-content: space-evenly;

    .top-down {
      text-align: left;
      width: 378px;
      margin: 120px 0 0 107px;

      .down-title {
        font-size: 50px;
        color: #ffffff;
        line-height: 55px;
      }

      .down-content {
        font-size: 26px;
        color: #ffffff;
        line-height: 40px;
        margin: 25px 0 30px;
      }

      .down-img {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .img-item {

          img {
            width: 140px;
            height: 140px;
          }

          .img-Android {
            background: #00b38a;
            width: 100%;
            height: 40px;
            margin-top: 15px;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            font-weight: bold;
            font-size: 15px;
            color: #ffffff;
          }

          .img-IOS {
            background: #3598dc;
            width: 100%;
            height: 40px;
            margin-top: 15px;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            font-weight: bold;
            font-size: 15px;
            color: #ffffff;
          }
        }

      }
    }
  }
}

.shopping {
  height: 543px;

  .notice {
    font-size: 16px;
    color: #333333;
    margin-top: 100px;
  }

  .shopping-title {
    font-size: 40px;
    color: #333333;
    line-height: 40px;
    margin: 100px;
    text-align: center;
    font-weight: bold;
  }

  .shopping-list {
    display: flex;
    justify-content: center;

    .list-item {
      width: 10%;
    }
  }
}
</style>
